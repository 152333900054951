import GridTileCard from "../../../../shared/components/GridTileCard";
import CheckBox from "../../../../shared/components/ui/CheckBox";

import { accountType } from "../../../../constants/accountType";

import { useAuth } from "../../../../store/authContext";

export default function PromotionPane({
  profileImage,
  fullName,
  id,
  gender,
  promoted,
  published,
  studentClass,
  isChecked = false,
  showPromoted = true,
  onCheckClicked,
}) {
  const { user } = useAuth();

  const { userType } = user;

  return (
    <>
      <label className="mb-[20px] last:mb-0 ">
        <GridTileCard
          classes="items-center cursor-pointer"
          verticalPadding="py-[20px]"
        >
          {/* Profile image, name and ID */}
          <div
            className={`flex space-x-[18px] ${
              userType === accountType.TEACHER && "col-span-5"
            } ${showPromoted ? "col-span-4" : "col-span-6"}`}
          >
            <div className="h-[50px] w-[50px] overflow-hidden rounded-[50px] bg-red-200">
              <img
                src={profileImage ? profileImage : "https://picsum.photos/200"}
                className="h-full w-full object-cover "
                alt="Random stuff"
              />
            </div>

            <div className="flex flex-col space-y-[2px]">
              <p className="text-[16px] font-bold text-black-shade1">
                {fullName}
              </p>
              <p className="text-[14px] text-[#91929E]">{id}</p>
            </div>
          </div>

          {/* Gender */}
          <div className="col-span-2 flex flex-col justify-self-center">
            <p className="text-[16px] text-[#91929E]">Gender</p>
            <p className="text-[16px] text-black-shade1">{gender}</p>
          </div>

          {/* Promoted */}
          {showPromoted && (
            <div className="col-span-2 flex flex-col justify-self-start">
              <p className="text-[16px] text-[#91929E]">Promoted</p>
              <p className="text-[16px] text-black-shade1">
                {promoted ? "True" : "False"}
              </p>
            </div>
          )}

          {/* Published */}
          <div className="col-span-2 flex flex-col justify-self-start">
            <p className="text-[16px] text-[#91929E]">Published</p>
            <p className="text-[16px] text-black-shade1">
              {published ? "True" : "False"}
            </p>
          </div>

          {/* Class */}
          <div className="col-span-1 flex flex-col justify-self-center">
            <p className="text-[16px] text-[#91929E]">Class</p>
            <p className="text-[16px] uppercase text-black-shade1">
              {studentClass}
            </p>
          </div>

          {/* Checkbox */}
          <span className="col-span-1 place-self-end">
            <CheckBox checked={isChecked} onChange={onCheckClicked} />
          </span>
        </GridTileCard>
      </label>
    </>
  );
}
