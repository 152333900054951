export const getYearRange = (
  currentYear = new Date().getFullYear(),
  howManyYears = 100
) => {
  // const currentYear = new Date().getFullYear();
  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );
  let years = range(currentYear, currentYear - howManyYears, -1);

  return years;
};

export const generateYears = (startYear, count) => {
  const years = [];
  for (let i = 0; i < count; i++) {
    years.push(startYear + i);
  }
  return years;
};
