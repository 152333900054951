import { useEffect, useState } from "react";

import NormalSelect from "../../../../shared/components/ui/NormalSelect";
import PageSearchIconAndLabel from "../../../../shared/components/PageSearchIconAndLabel";
import PageLoading from "../../../../shared/components/ui/PageLoading";
import PromotionPane from "./PromotionPane";
import DismissibleModal from "../../../../shared/components/modals/DismissibleModal";
import {
  AddButtonBordered,
  AddButtonPlain,
  CheckButton,
  DeleteButton,
} from "../../../../shared/components/ui/Buttons";
import PaginationIndicator from "../../../../shared/components/pagination/PaginationIndicator";

import { schoolClasses } from "../../../../assets/lists/schoolClasses";
import { schoolTerms } from "../../../../assets/lists/schoolTerms";
import { getYearRange } from "../../../../utils/getYearRange";

import { usePromotion } from "../../store/promotionStore/promotionContext";
import { debugPrint } from "../../../../utils/debugPrint";

const Promotion = () => {
  const currentYear = new Date().getFullYear() + 1;

  const years = getYearRange(currentYear);

  const {
    state,
    fetchInitialData,
    handleCheckedStudent,
    handleCheckAllStudents,
    deleteStudentsFromAllStudents,
    handlePromoteStudents,
    handlePublishStudentResults,
    clearPublishedErrors,
    fetchNextData,
    fetchPreviousData,
  } = usePromotion();

  const {
    currentPage,
    isInitial,
    isLoading,
    isPromoting,
    isPublishing,
    error,
    message,
    allStudents,
    paginatedStudentData,
    checkedStudents,
    publishedErrorMessages,
  } = state;

  const [isFilterButtonActive, setIsFilterButtonAction] = useState(false);

  const [isPromotionButtonActive, setIsPromotionButtonActive] = useState(false);

  const [showPromoted, setShowPromoted] = useState(false);

  /// Local state to toggle published error dashboard depending on if an error
  /// is present after publishing students
  const [showModal, setShowModal] = useState(false);

  const [filterParams, setFilterParams] = useState({
    currentSession: "",
    currentTerm: "",
    nextSession: "",
    currentClass: "",
    promotingTo: "",
  });

  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

  useEffect(() => {
    if (
      checkedStudents.length < paginatedStudentData.length &&
      isSelectAllChecked === true
    ) {
      setIsSelectAllChecked(false);
      return;
    }
    if (
      checkedStudents.length === paginatedStudentData.length &&
      isSelectAllChecked === false
    ) {
      setIsSelectAllChecked(true);
    }
  }, [checkedStudents.length, paginatedStudentData.length, isSelectAllChecked]);

  const handleSelectAllClicked = status => {
    setIsSelectAllChecked(status);

    handleCheckAllStudents(status);
  };

  const handleFilterSelection = (name, value) => {
    const updatedObj = {
      [name]: value,
    };

    setFilterParams(prevState => {
      return { ...prevState, ...updatedObj };
    });
  };

  useEffect(() => {
    if (
      filterParams.currentSession &&
      filterParams.currentClass &&
      filterParams.currentTerm
    ) {
      setIsFilterButtonAction(true);
    } else {
      setIsFilterButtonAction(false);
    }
  }, [
    filterParams.currentSession,
    filterParams.currentClass,
    filterParams.currentTerm,
  ]);

  useEffect(() => {
    if (
      filterParams.currentSession &&
      filterParams.currentClass &&
      filterParams.currentTerm &&
      filterParams.nextSession &&
      filterParams.promotingTo &&
      checkedStudents.length >= 1
    ) {
      setIsPromotionButtonActive(true);
    } else {
      setIsPromotionButtonActive(false);
    }
  }, [
    filterParams.currentSession,
    filterParams.currentClass,
    filterParams.currentTerm,
    filterParams.nextSession,
    filterParams.promotingTo,
    checkedStudents.length,
  ]);

  /// This side-effect checks
  useEffect(() => {
    if (publishedErrorMessages.length >= 1) {
      setShowModal(true);
    }
  }, [publishedErrorMessages]);

  const [nextSessionOptions, setNextSessionOptions] = useState(null);

  const [promotionToOptions, setPromotingToOptions] = useState(null);

  const _handleFilterClicked = params => {
    if (filterParams.currentTerm?.toLowerCase() === "third") {
      setNextSessionOptions(years);
      setPromotingToOptions(schoolClasses);
    } else {
      const sessionOption = [filterParams.currentSession];
      setNextSessionOptions(sessionOption);

      const promotingOption = [filterParams.currentClass];

      setPromotingToOptions(promotingOption);
    }

    if (filterParams.currentTerm?.toLowerCase() === "third") {
      setShowPromoted(true);
    } else {
      setShowPromoted(false);
    }
    fetchInitialData(params);
  };

  const getCheckedStatus = studentId => {
    let checkedStatus = false;

    for (const studentData of checkedStudents) {
      if (
        studentData?.studentId === studentId ||
        studentData?.studentAuthId === studentId
      ) {
        checkedStatus = true;
        break;
      }
    }

    return checkedStatus;
  };

  return (
    <>
      <DismissibleModal
        showModal={showModal}
        setShowModal={setShowModal}
        height="h-[500px]"
        width="w-[500px]"
        headerText="Error Message"
        contentStyle="mt-[32px]"
        onCloseButtonClicked={() => {
          setShowModal(false);
          clearPublishedErrors();
        }}
      >
        <table className="table-fixed border-collapse overflow-auto">
          <thead className="text-[15.65px] leading-[22px]">
            <tr className="border-b-[4px] border-[#B8B8B8]">
              <th className="w-[64px] p-[10px] text-left font-normal">S/N</th>
              <th className="w-full p-[10px] text-center font-normal">
                Message
              </th>
            </tr>
            <tr></tr>
          </thead>
          <tbody className="bg-white">
            {publishedErrorMessages?.map((data, index) => (
              <tr
                key={index}
                className="cursor-default border-b-[2px] border-[#E5E5E5]"
              >
                <td className="border-r-[1px] border-grey-light p-[10px]">
                  {index + 1}
                </td>
                <td className="border-r-[1px] border-grey-light p-[10px]">
                  {data}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </DismissibleModal>
      <div className="flex h-full flex-col rounded-[22px] bg-[#FAFAFC] p-[50px]">
        <div className="mb-[32px] flex items-center justify-between">
          <h2 className="text-[36px] font-semibold leading-[54px] text-black">
            Promotion
          </h2>
          <div className="flex space-x-[20px]">
            {!isLoading && !error && checkedStudents.length >= 1 && (
              <DeleteButton
                backgroundColor="bg-[#FAF2F1]"
                clicked={deleteStudentsFromAllStudents}
              >
                Remove
              </DeleteButton>
            )}
            {!isLoading && !error && allStudents.length >= 1 && (
              <CheckButton
                backgroundColor="bg-[#FAF2F1]"
                onClick={handleSelectAllClicked}
                checked={isSelectAllChecked}
              >
                Select All
              </CheckButton>
            )}
            {!isLoading && !error && allStudents.length >= 1 && (
              <AddButtonBordered
                className="w-[170px]"
                isLoading={isPromoting}
                clicked={() => handlePromoteStudents(filterParams)}
                disabled={!isPromotionButtonActive}
              >
                Promote
              </AddButtonBordered>
            )}
            {!isLoading && !error && allStudents.length >= 1 && (
              <AddButtonPlain
                className="w-[170px] px-[30px]"
                isLoading={isPublishing}
                clicked={handlePublishStudentResults}
                disabled={isPublishing}
              >
                Publish
              </AddButtonPlain>
            )}
          </div>
        </div>

        <div className="mb-[19px] flex w-full flex-row items-end justify-around">
          <NormalSelect
            label="Current Session"
            className={`w-auto`}
            value={filterParams.currentSession}
            options={getYearRange(new Date().getFullYear() + 1)}
            handleSelect={event =>
              handleFilterSelection("currentSession", event.value)
            }
          />
          <NormalSelect
            label="Current Class"
            className={`w-auto`}
            value={filterParams.currentClass}
            options={schoolClasses}
            handleSelect={event =>
              handleFilterSelection("currentClass", event.value)
            }
          />
          <NormalSelect
            label="Current term"
            className={`w-auto`}
            value={filterParams.currentTerm}
            options={schoolTerms}
            handleSelect={event =>
              handleFilterSelection("currentTerm", event.value)
            }
          />
          {allStudents?.length >= 1 &&
            filterParams.currentTerm?.toLowerCase() === "third" && (
              <>
                <NormalSelect
                  label="Next Session"
                  className={`w-auto`}
                  value={filterParams.nextSession}
                  options={nextSessionOptions}
                  handleSelect={event =>
                    handleFilterSelection("nextSession", event.value)
                  }
                />

                <NormalSelect
                  label="Promoting To"
                  className={`w-auto`}
                  value={filterParams.promotingTo}
                  options={promotionToOptions}
                  handleSelect={event =>
                    handleFilterSelection("promotingTo", event.value)
                  }
                />
              </>
            )}

          <AddButtonPlain
            className={`px-[50px]`}
            showIcon={false}
            clicked={() => _handleFilterClicked(filterParams)}
            disabled={!isFilterButtonActive && !isPromoting && !isPublishing}
          >
            Filter
          </AddButtonPlain>
        </div>

        {isInitial ? (
          <div className="flex h-full items-center justify-center">
            <PageSearchIconAndLabel label="Search for Students" />
          </div>
        ) : isLoading ? (
          <PageLoading classes="h-full" />
        ) : error ? (
          <div className="flex h-full items-center justify-center">
            <PageSearchIconAndLabel label={error ?? "Something went wrong"} />
          </div>
        ) : allStudents.length === 0 ? (
          <div className="flex h-full items-center justify-center">
            <PageSearchIconAndLabel label={message} />
          </div>
        ) : (
          <>
            {paginatedStudentData.map((studentData, index) => {
              const studentFullName = `${studentData?.student?.firstName} ${studentData?.student?.lastName}`;
              const studentId =
                studentData?.studentId ?? studentData?.student?.id;
              const studentGender = studentData?.student?.gender;
              const studentClass = `${studentData?.class}${studentData?.subClass}`;
              const promoted = studentData?.promoted ?? false;
              const published = studentData?.published ?? false;

              return (
                <PromotionPane
                  key={studentId ?? index}
                  fullName={studentFullName}
                  id={studentId}
                  gender={studentGender}
                  studentClass={studentClass}
                  promoted={promoted}
                  published={published}
                  isChecked={getCheckedStatus(studentId)}
                  showPromoted={showPromoted}
                  onCheckClicked={() => handleCheckedStudent(studentData)}
                />
              );
            })}
            <PaginationIndicator
              classes="self-end"
              currentPageNumber={currentPage}
              itemCount={paginatedStudentData?.length}
              totalCount={allStudents?.length}
              onBackArrowClick={fetchPreviousData}
              onForwardArrowClick={fetchNextData}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Promotion;
