import { useState } from "react";
import { Formik, Form } from "formik";
import _ from "lodash";
import axios from "axios";

import LocationInfo from "../LocationInfo";
import ProfileMainInfo from "../ProfileMainInfo";
import TeacherJobInfo from "./TeacherJobInfo";
import EditingTeacherProfile from "./EditingTeacherProfile";
import { Edit } from "../../../icons";

import {
  showSuccessToast,
  showErrorToast,
} from "../../../../../utils/toastHandler";
import { teacherProfileSchema } from "../../../../../utils/validationSchemas/teacherProfileSchema";
import {
  extractDifferenceInObject,
  removeWhiteSpacesFromObjValues,
} from "../../../../../utils/helperFunctions";

import { removeNullOrUndefinedValuesFromObject } from "../../../../../utils/objectHelperFunctions";

import { accountType } from "../../../../../constants/accountType";

import { updateTeacherProfile } from "../../../../../features/school/services/schoolService";
import urlSettings from "../../../../../config/apiConfig/apiConstants";

import { useTeacher } from "../../../../../store/teacherStore/teacherContext";
import { useAuth } from "../../../../../store/authContext";

import { debugPrint } from "../../../../../utils/debugPrint";

const TeacherProfile = ({
  teacherAuthId,
  profileImage,
  mainInfo,
  jobInfo,
  locationInfo,
  otherInfo,
  salaryInfo,
  onEditProfileSuccess,
}) => {
  const { user } = useAuth();
  const userType = user?.userType;

  const { handleTeacherEdit } = useTeacher();

  const [isEditing, setIsEditing] = useState(false);

  const handleOnCancelEdit = () => {
    setIsEditing(false);
  };

  const originalValues = {
    profileImage: null,
    firstName: mainInfo?.["First Name"] ?? "",
    lastName: mainInfo?.["Last Name"] ?? "",
    middleName: mainInfo?.["Middle Name"] ?? "",
    phoneNumber: mainInfo?.["Contact"] ?? "",
    teacherEmail: mainInfo?.["Email"] ?? "",
    dateOfBirth: mainInfo?.["Date of Birth"] ?? null, // <- Main info ends here
    subjects: jobInfo?.["Subjects"],
    classes: jobInfo?.["Classes"],
    yearsOfExperience: jobInfo?.["Years of Experience"] ?? "",
    country: locationInfo?.["Country"] ?? "",
    state: locationInfo?.["State"] ?? "",
    LGA: locationInfo?.["LGA"] ?? "",
    address: locationInfo?.["Address"] ?? "",
    formerEmployerName: otherInfo?.["Former Employer Name"] ?? "",
    formerJobTitle: otherInfo?.["Former Job Title"] ?? "",
    // documents: otherInfo?.["Documents"], // TODO: Remove if not used in the updated payload data
    newDocuments: [], // This is hold new documents for teacher
    salary: salaryInfo?.salary ?? 0,
    accountName: salaryInfo?.accountName ?? "",
    accountNumber: salaryInfo?.accountNumber ?? "",
    bank: salaryInfo?.bank ?? "",
    bankCode: salaryInfo?.bankCode ?? "",
  };

  const initialValues = { ...originalValues };

  const formHandler = async values => {
    let formattedData = removeWhiteSpacesFromObjValues(values);

    debugPrint("formattedData -", formattedData);

    const {
      subjects: teacherSubjects,
      classes: teacherClasses,
      accountName,
      accountNumber,
      bank,
      bankCode,
    } = formattedData;

    const payloadSubjects = teacherSubjects.map(obj => {
      if (typeof obj === "object" && obj !== null) {
        return obj.value;
      } else {
        return obj;
      }
    });

    debugPrint("payloadSubjects =>", payloadSubjects);

    const payloadClasses = teacherClasses.map(obj => {
      if (typeof obj === "object" && obj !== null) {
        return obj.value;
      } else {
        return obj;
      }
    });

    formattedData = {
      ...formattedData,
      subjects: payloadSubjects,
      classes: payloadClasses,
    };

    const isEqual = _.isEqual(formattedData, originalValues);

    if (isEqual) {
      handleOnCancelEdit();
      return;
    }

    const diff = extractDifferenceInObject(originalValues, formattedData);

    const {
      profileImage,
      firstName,
      lastName,
      middleName,
      newDocuments,
      subjects,
      classes,
      ...params
    } = diff;

    let profileUploadedUrl = null;

    try {
      /// Upload new profile image if selected
      if (profileImage) {
        const profileFormData = new FormData();
        profileFormData.append("file", profileImage);
        profileFormData.append("upload_preset", "project-image");

        const profileCloudinary = await axios.post(
          urlSettings.CLOUDINARY_URL,
          profileFormData
        );

        profileUploadedUrl = profileCloudinary.data.secure_url;
      }

      // FIXME: Add firstName, lastName, middleName when backend has been fixed
      const obj = {
        ...params,
        accountName,
        accountNumber,
        bank,
        bankCode,
        teacherAuthId: teacherAuthId,
        profileImageUrl: profileUploadedUrl,
      };

      const payload = removeNullOrUndefinedValuesFromObject(obj);

      const response = await updateTeacherProfile(payload);

      debugPrint("TeacherProfile - formHandler -- response ->", response);

      if (response.data.status === true) {
        showSuccessToast("Success");
        handleTeacherEdit(teacherAuthId, payload);
        handleOnCancelEdit();

        onEditProfileSuccess();
      } else {
        throw new Error("Could not update data");
      }

      // TODO: Handle response
    } catch (error) {
      //handle LOGS

      showErrorToast(error.message);
    }
  };

  return (
    <div className="h-[500px] w-[1024px] p-[10px]">
      <>
        {isEditing ? (
          <Formik
            initialValues={initialValues}
            validationSchema={teacherProfileSchema}
            onSubmit={formHandler}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              setFieldTouched,
              setTouched,
              setFieldValue,
            }) => (
              <Form
                onSubmit={handleSubmit}
                className="flex flex-col"
                // autoComplete="off"
                autoComplete="new-password"
              >
                <EditingTeacherProfile
                  profileImage={profileImage}
                  values={values}
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  setTouched={setTouched}
                  isSubmitting={isSubmitting}
                  onCancel={handleOnCancelEdit}
                  onSubmit={handleSubmit}
                />
              </Form>
            )}
          </Formik>
        ) : (
          <div className="flex flex-col">
            <div className="mb-[42px] flex items-center justify-between">
              <div className="mr-[488px] flex items-center">
                {/* Image component */}
                <div className="mr-[21px] h-[107px] w-[107px] rounded-full bg-grey">
                  {profileImage && (
                    <img
                      src={profileImage}
                      className="h-[107px] w-[107px] rounded-full object-cover"
                      alt="Random stuff"
                    />
                  )}
                </div>
                <div>
                  <h1 className="text-[24px] font-extrabold">{`${mainInfo?.["First Name"]} ${mainInfo?.["Last Name"]}`}</h1>
                  <p className="text-[14px]">{`ID: ${mainInfo?.["User ID"]}`}</p>
                </div>
              </div>
              {/* TODO: Extract "EditButton" component */}
              <div
                className="flex cursor-pointer items-center space-x-[6px]"
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                {userType === accountType.SCHOOL && (
                  <>
                    <h1>Edit profile</h1>
                    <span className="rounded-[14px] bg-[#F4F9FD] p-[8px]">
                      <Edit />
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="relative h-[2px] w-full overflow-visible bg-grey"></div>

            <div className="grid grid-cols-2">
              <ProfileMainInfo mainInfo={mainInfo} />
              <TeacherJobInfo jobInfo={jobInfo} />
              <LocationInfo locationInfo={locationInfo} />
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default TeacherProfile;
