/// Authentication endpoint base URL
const AUTH_URL =
  process.env.NODE_ENV === "production"
    ? `${process.env.REACT_APP_BASE_URL_PROD}/auth`
    : `${process.env.REACT_APP_BASE_URL_DEV}/auth`;

/// School services endpoint base URL
const SCHOOL_URL =
  process.env.NODE_ENV === "production"
    ? `${process.env.REACT_APP_BASE_URL_PROD}/school/`
    : `${process.env.REACT_APP_BASE_URL_DEV}/school/`;

const TEACHER_URL =
  process.env.NODE_ENV === "production"
    ? `${process.env.REACT_APP_BASE_URL_PROD}/teacher/`
    : `${process.env.REACT_APP_BASE_URL_DEV}/teacher/`;

const STUDENT_URL =
  process.env.NODE_ENV === "production"
    ? `${process.env.REACT_APP_BASE_URL_PROD}/student/`
    : `${process.env.REACT_APP_BASE_URL_DEV}/student/`;

/// Image upload service endpoint base URL
const IMAGE_UPLOAD_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_IMAGE_UPLOAD_URL_PROD
    : process.env.REACT_APP_IMAGE_UPLOAD_URL_DEV;

/// Cloudinary service endpoint
const CLOUDINARY_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_CLOUDINARY_URL_PROD
    : process.env.REACT_APP_CLOUDINARY_URL_DEV;

const urlSettings = {
  AUTH_URL,
  SCHOOL_URL,
  TEACHER_URL,
  STUDENT_URL,
  IMAGE_UPLOAD_URL,
  CLOUDINARY_URL,
};

export default urlSettings;
