import { createContext, useContext, useEffect, useReducer } from "react";

import resultsReducer, { initialState } from "./resultsReducer";
import { resultsActions } from "./resultsActions";

import { getSchoolStudentsResults } from "../../features/school/services/schoolService";
import { getTeachersStudentResult } from "../../features/teacher/services/teacherService";
import {
  getStudentResult,
  getStudentsStudentResult,
} from "../../features/student/services/studentService";

import { accountType } from "../../constants/accountType";
import { useAuth } from "../authContext";

import { showErrorToast, showInfoToast } from "../../utils/toastHandler";
import { debugPrint } from "../../utils/debugPrint";
import { areTwoObjectsEqual } from "../../utils/helperFunctions";

const ResultsContext = createContext(initialState);

ResultsContext.displayName = "ResultsContext";

const ResultsProvider = ({ children }) => {
  const { user } = useAuth();

  const userType = user?.userType;

  const [state, dispatch] = useReducer(resultsReducer, initialState);

  useEffect(() => {
    const checkUserTypeAndMaybeSetClassFilter = () => {
      if (userType === accountType.TEACHER) {
        const updatedFilterObj = {
          classFilter: user?.teacherClasses[0] ?? "",
        };

        dispatch({
          type: resultsActions.UPDATE_FILTER_PARAMS,
          payload: { updatedFilterObj },
        });
      }
    };

    checkUserTypeAndMaybeSetClassFilter();
  }, []);

  const _handleResultDataFetch = async (
    currentPage,
    classFilter,
    termFilter,
    yearFilter
  ) => {
    debugPrint("Join the new link");
    switch (userType) {
      case accountType.SCHOOL:
        const { data: schoolStudentResults } = await getSchoolStudentsResults(
          currentPage,
          termFilter,
          classFilter,
          yearFilter
        );
        return schoolStudentResults;
      case accountType.TEACHER:
        const { data: teacherStudentResults } = await getTeachersStudentResult(
          currentPage,
          classFilter,
          termFilter,
          yearFilter
        );
        return teacherStudentResults;
      case accountType.STUDENT:
        const { data: studentsStudentResult } = await getStudentResult();

        return studentsStudentResult;

      default:
        throw new Error("Something went wrong");
    }
  };

  // useEffect(() => {
  //   debugPrint("resultsContext - useEffect -- CALLED!!!");

  //   const fetchStudentResultData = async () => {
  //     try {
  //       const studentResultData = await _handleResultDataFetch();

  //       debugPrint(
  //         "resultContext - useEffect -- fetchStudentResultData -> studentResultData =",
  //         studentResultData
  //       );

  //       const { status, message, data } = studentResultData;

  //       debugPrint(
  //         "resultContext - useEffect -- fetchStudentResultData -> data =",
  //         data
  //       );

  //       if (status === false) {
  //         throw new Error(message ?? "Something went wrong. Try again");
  //       }

  //       if (status === true && data == null) {
  //         showInfoToast(message ?? "No result data found");

  //         dispatch({
  //           type: resultsActions.REQUEST_SUCCESS,
  //           payload: { allStudents: [] },
  //         });
  //         return;
  //       }

  //       dispatch({
  //         type: resultsActions.REQUEST_SUCCESS,
  //         payload: { allStudents: [...data] },
  //       });
  //     } catch (err) {
  //       // TODO: Handle error
  //     } finally {
  //       dispatch({
  //         type: resultsActions.UPDATE_LOADING_STATE,
  //         payload: { isLoading: false },
  //       });
  //     }
  //   };
  //   if (userType === accountType.STUDENT) {
  //     dispatch({
  //       type: resultsActions.INITIALIZE_DEFAULT_LOAD,
  //       payload: { isLoading: true, isInitial: false, currentPage: 1 },
  //     });

  //     fetchStudentResultData();
  //   }
  // }, []);

  const fetchInitialData = async filterParams => {
    const cachedFilters = {
      termFilter: state.cachedTermFilter,
      classFilter: state.cachedClassFilter,
      subClassFilter: state.cachedSubClassFilter,
      yearFilter: state.cachedYearFilter,
    };

    if (
      userType !== accountType.STUDENT &&
      areTwoObjectsEqual(cachedFilters, filterParams)
    ) {
      // Don't run this function if the filter params passed is the same with the filter
      // in cache
      return;
    }

    dispatch({
      type: resultsActions.INITIALIZE_DEFAULT_LOAD,
      payload: { isLoading: true, isInitial: false, currentPage: 1 },
    });

    const { termFilter, classFilter, subClassFilter, yearFilter } =
      filterParams;

    /// Caching the filter params data to be used when fetching paginated data
    dispatch({
      type: resultsActions.CACHE_FILTER_PARAMS,
      payload: {
        cachedTermFilter: termFilter,
        cachedClassFilter: classFilter,
        cachedSubClassFilter: subClassFilter,
        cachedYearFilter: yearFilter,
      },
    });

    try {
      const responseData = await _handleResultDataFetch(
        1,
        classFilter,
        termFilter,
        yearFilter
      );

      debugPrint(
        "resultContext - fetchInitialData -- responseData ->",
        responseData
      );

      const { status, message, data } = responseData;

      if (status === true && data == null) {
        showInfoToast(message ?? "No result data found");

        dispatch({
          type: resultsActions.REQUEST_SUCCESS,
          payload: { allStudents: [] },
        });
        return;
      }

      if (status === true) {
        /// When fetching student marks for a "school" or "teacher" account the
        /// data is returned as an array. But for a "student" account, the data
        /// is returned as an object.
        ///
        /// For the data to conform to the UI structure in place, for "student",
        /// the data is pushed into an array
        let studentResultData = [];

        const isDataAnArray = Array.isArray(data);

        if (isDataAnArray) {
          // "school" and "teacher" account types
          studentResultData = [...data];
        } else {
          // "student" account type
          studentResultData.push(data);
        }

        dispatch({
          type: resultsActions.REQUEST_SUCCESS,
          payload: {
            allStudents: [...studentResultData],
          },
        });
      } else {
        throw new Error(message ?? "Something went wrong");
      }
    } catch (err) {
      showErrorToast(err.message);
      dispatch({
        type: resultsActions.ADD_ERROR,
        payload: { error: err.message },
      });
    } finally {
      dispatch({
        type: resultsActions.UPDATE_LOADING_STATE,
        payload: { isLoading: false },
      });
    }
  };

  /// This function is responsible for setting the filter values for the
  /// result dashboard
  const handleFilterSelection = (name, value) => {
    const updatedFilterObj = {
      [name]: value,
    };

    dispatch({
      type: resultsActions.UPDATE_FILTER_PARAMS,
      payload: { updatedFilterObj },
    });
  };

  /// This function is responsible for updating the principal's comment of a
  /// given student.
  ///
  /// NOTE: The "studentResultData" argument is an object of the student's result
  /// data which will contain the updated comment
  const updateStudentResultCommentInState = studentResultData => {
    const cachedStudentsResult = [...state.allStudents];

    const indexOfStudent = cachedStudentsResult.findIndex(
      value => value.studentAuthId === studentResultData.studentAuthId
    );

    if (indexOfStudent === -1) {
      return;
    }

    cachedStudentsResult.splice(indexOfStudent, 1, studentResultData);

    dispatch({
      type: resultsActions.REQUEST_SUCCESS,
      payload: { allStudents: [...cachedStudentsResult] },
    });
  };

  const value = {
    state,
    fetchInitialData,
    handleFilterSelection,
    updateStudentResultCommentInState,
  };

  return (
    <ResultsContext.Provider value={value}>{children}</ResultsContext.Provider>
  );
};

const useResults = () => {
  const context = useContext(ResultsContext);

  if (context === undefined) {
    throw new Error("useResults must be used within ResultsContext");
  }

  return context;
};

export { ResultsProvider, useResults };
