import { useState } from "react";
import { PropTypes } from "prop-types";
import PinField from "react-pin-field";

import Button from "../../../../../shared/components/ui/Button";
import PageLoading from "../../../../../shared/components/ui/PageLoading";

import {
  approvePayroll,
  resendPayrollOtpCode,
} from "../../../services/schoolService";

import {
  showErrorToast,
  showSuccessToast,
} from "../../../../../utils/toastHandler";

import { payrollStatus } from "../payrollStatus";
import { usePayroll } from "../../../store/payrollStore/payrollContext";

import { debugPrint } from "../../../../../utils/debugPrint";

const CreatePayrollOtpModal = ({ payroll, onUpdatePayrollStatus }) => {
  debugPrint("CreatePayrollOtpModal - payroll --", payroll);
  const { updatePayroll } = usePayroll();

  const [modalState, setModalState] = useState({
    resendOtpCount: 1,
    isRequestingOtp: false, // When requesting for OTP

    otpCode: "",
    otpError: false,

    isSubmitting: false,
  });

  const handleEmailCodeComplete = value => {
    setModalState(prevState => {
      return { ...prevState, otpCode: value, otpError: false };
    });
  };

  const resendOtpHandler = async () => {
    setModalState(prevState => {
      return { ...prevState, isRequestingOtp: true };
    });
    try {
      const { schoolAuthId, id } = payroll;

      const payload = {
        schoolAuthId: schoolAuthId,
        payrollId: id,
      };

      const { data } = await resendPayrollOtpCode(payload);

      const { status, message } = data;

      if (status === true) {
        showSuccessToast(message ?? "Success");
        setModalState(prevState => {
          return {
            ...prevState,
            resendOtpCount: prevState.resendOtpCount + 1,
          };
        });
      } else {
        throw new Error(message ?? "Problem sending OTP. Try again later.");
      }
    } catch (err) {
      showErrorToast(err?.message ?? "Failed");
    } finally {
      setModalState(prevState => {
        return { ...prevState, isRequestingOtp: false };
      });
    }
  };

  const handleSubmit = async () => {
    if (modalState.isRequestingOtp) {
      return;
    }

    if (modalState.otpCode.length < 6) {
      setModalState(prevState => {
        return { ...prevState, otpError: true };
      });
      return;
    }

    setModalState(prevState => {
      return { ...prevState, otpError: false, isSubmitting: true };
    });

    try {
      const payload = {
        // schoolAuthId: payroll.schoolAuthId,
        payrollId: payroll.id,
        otp: modalState.otpCode,
      };

      const { data } = await approvePayroll(payload);

      const { status, message } = data;

      if (status === true) {
        showSuccessToast(message ?? "Success");

        onUpdatePayrollStatus(payrollStatus.APPROVED, true);

        // Change the payroll "PayrollProcessingState" to "approved"
        const updatedPayroll = {
          ...payroll,
          PayrollProcessingState: payrollStatus.APPROVED,
        };

        setModalState(prevState => {
          return { ...prevState, isSubmitting: false };
        });

        // Updating the payroll in state
        updatePayroll(updatedPayroll);
      } else {
        throw new Error(message ?? "Something went wrong. Try again");
      }
    } catch (err) {
      setModalState(prevState => {
        return { ...prevState, isSubmitting: false };
      });
      showErrorToast(err.message);
    }
  };

  return (
    <div className="flex h-auto w-[576px] flex-col">
      <h1 className="mb-[20px] text-[20px] font-semibold">Enter OTP</h1>

      <div className="flex flex-col px-[48px]">
        <p className="mb-[16px] text-[14px] font-normal leading-[20px]">
          An OTP has been sent to your email link will sent to you, let's access
          our the best recommendation for you.
        </p>

        <p className="mb-[4px] text-[14px] font-bold text-[#7D8592]">
          Code from EMAIL
        </p>
        <div className="mb-[24px] flex items-stretch justify-between">
          <PinField
            className="h-[48px] w-[52px] rounded-[8px] border-[1px] border-[#D8E0F0] text-center"
            length={6}
            onComplete={handleEmailCodeComplete}
          />
          {modalState.otpError && (
            <p className="text-[14px] text-accent">Fields cannot be empty</p>
          )}
        </div>

        <Button
          type="submit"
          extraClasses="self-center"
          isLoading={modalState.isSubmitting}
          onClick={handleSubmit}
        >
          <div className="flex items-center justify-center space-x-2">
            <span className=" ">Proceed</span>
          </div>
        </Button>

        {modalState.resendOtpCount < 3 && (
          <p className="mt-[20px] flex self-center text-[14px] font-normal text-[#4F4F4F]">
            Didn't get the code{" "}
            {modalState.isRequestingOtp ? (
              <PageLoading size="16px" classes="ml-[4px]" />
            ) : (
              <span
                className="ml-[4px] cursor-pointer font-semibold text-blue"
                onClick={resendOtpHandler}
              >
                Resend({modalState.resendOtpCount}/3)
              </span>
            )}
          </p>
        )}
      </div>
    </div>
  );
};

CreatePayrollOtpModal.propTypes = {
  payroll: PropTypes.object,
  onUpdatePayrollStatus: PropTypes.func,
};

export default CreatePayrollOtpModal;
