import { useState } from "react";

import UndismissibleModal from "./modals/UndismissibleModal";
import StudentProfile from "./modals/profile/student/StudentProfile";
import GridTileCard from "./GridTileCard";
import MoreVertButton from "./ui/MoreVertButton";
import IconAndLabel from "./ui/IconAndLabel";
import CheckBox from "./ui/CheckBox";
import ImageAvatar from "./ImageAvatar";
import { Edit } from "./icons";

import { convertToReadableDate } from "../../utils/helperFunctions";
import { accountType } from "../../constants/accountType";

import { useAuth } from "../../store/authContext";

/// For the [Students] component provide data via studentAuthId, mainInfo,
/// schoolInfo, locationInfo, guardianInfo
///
/// For the [Promotion] component provide data via fullName, id, gender,
/// dateOfBirth, parent, studentClass
///
/// profileImage is applicable for either components
export default function StudentPane({
  studentAuthId, // obj
  mainInfo, // obj
  schoolInfo, // obj
  locationInfo, // obj
  guardianInfo, // obj
  profileImage,
  showCheckBox = false,

  fullName,
  id,
  gender,
  dateOfBirth,
  parent,
  studentClass,
  onCheckClicked,
}) {
  const { user } = useAuth();

  const { userType } = user;

  const [showModal, setShowModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleShowModel = () => {
    setShowModal(prevState => !prevState);
  };

  const handleCheckboxChange = () => {
    setChecked(prevState => !prevState);

    if (onCheckClicked != null) {
      onCheckClicked();
    }
  };

  const handleTileCardClicked = () => {
    // if (e != null) {
    //   e.stopPropagation();
    // }
    if (showCheckBox) {
      handleCheckboxChange();
      return;
    }

    handleShowModel();
  };

  return (
    <>
      <UndismissibleModal
        header={<p className="text-[22px] font-bold">Profile</p>}
        showModal={showModal}
        setShowModal={setShowModal}
        width="1088px"
        // extraHeaderClass="w-full"
      >
        <StudentProfile
          studentAuthId={studentAuthId}
          profileImage={profileImage}
          mainInfo={mainInfo}
          schoolInfo={schoolInfo}
          locationInfo={locationInfo}
          guardianInfo={guardianInfo}
          onEditProfileSuccess={() => setShowModal(false)}
          // isEditingProfile={isEditingProfile}
        />
      </UndismissibleModal>
      <tbody className=" h-full w-full">
        <tr className="border-b-[1px] border-b-[#EAEBF0]">
          <td className="py-[12px] px-[24px]">
            <input
              id="teacher-checkbox"
              type="checkbox"
              value=""
              onChange={handleCheckboxChange}
              className=" h-[20px] w-[20px] rounded  border-[1px] text-primary focus:ring-[#D8E0F0]"
            />
          </td>

          <td className="text-[14px] font-medium leading-[20px] text-[#5F6D7E]">
            {id ? id : mainInfo?.["User ID"]}
          </td>

          <td className="flex items-center gap-4 py-[12px] px-[24px]">
            <div
              className={`col-span-4 flex space-x-[18px] ${
                userType === accountType.TEACHER && "col-span-5"
              }`}
            >
              <div className="flex h-[50px] w-[50px]  items-center justify-center overflow-hidden rounded-[50px] bg-grey text-center">
                <ImageAvatar
                  imageUrl={profileImage}
                  fullName={`${mainInfo?.["First Name"]} ${mainInfo?.["Last Name"]}`}
                />
              </div>
            </div>

            <div className="flex flex-col space-y-[2px]">
              <p className="text-[14px] font-bold leading-[20px] text-[#272D37]">
                {fullName
                  ? fullName
                  : `${mainInfo?.["First Name"]} ${mainInfo?.["Last Name"]}`}
              </p>
            </div>
          </td>
          {/* birthday */}

          <td className="">
            <div className="ml-4">
              <p className="text-[16px] text-black-shade1">
                {dateOfBirth
                  ? dateOfBirth
                  : mainInfo?.["Date of Birth"]
                  ? convertToReadableDate(mainInfo?.["Date of Birth"])
                  : "-- --"}
              </p>
            </div>
          </td>
          <td>
            {/* Parent */}
            {/* <div className="col-span-2 ml-6 flex flex-col justify-self-start bg-orange-500 tracking-wide"> */}
            <p className="text-[16px] text-black-shade1">
              {guardianInfo["Guardian's Name"] ?? "-- --"}
            </p>
            {/* </div> */}
          </td>

          {/* More vert */}
          <td>
            {showCheckBox ? (
              <CheckBox checked={checked} onChange={handleCheckboxChange} />
            ) : (
              userType === accountType.SCHOOL && (
                <MoreVertButton>
                  <div className="h-[96px] rounded-[24px] bg-white p-[24px]">
                    <IconAndLabel label="Edit" onClick={handleShowModel}>
                      <Edit className="text-black" />
                    </IconAndLabel>
                  </div>
                </MoreVertButton>
              )
            )}
          </td>
        </tr>
      </tbody>
    </>
  );
}
