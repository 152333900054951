import { useEffect, useRef, useState } from "react";
import blackLogo from "../../../assets/images/logo.png";
import * as Icons from "../../../shared/components/icons/index";
import SideNavLink from "../../../shared/components/SideNavLink";
import SchoolCard from "../../../shared/components/SchoolCard";
import { SideNavItem } from "../../../shared/components/SideNavItem";

import { comingSoonRoutes } from "../../../utils/routeNames";

const StudentSideNav = ({ extraClassName }) => {
  const container = useRef();

  const [navItemId, setNavItemId] = useState(-1);

  const handleNavItem = id => {
    setNavItemId(prevState => {
      if (prevState !== id) {
        return id;
      } else {
        return navItemId ? false : id;
      }
    });
  };

  const handleClickOutside = event => {
    if (container.current && !container.current.contains(event.target)) {
      setNavItemId(-1);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  return (
    <div
      className={`hidden h-full w-full flex-col items-center justify-between self-stretch py-6 lg:flex ${extraClassName}`}
      ref={container}
    >
      <div>
        <div className="mb-[32px] rounded-[5px] py-0 px-[56px]">
          <img src={blackLogo} className="h-[35px] w-[145px]" alt="logo" />
        </div>

        <div className="flex flex-col items-start">
          {/* Dashboard */}
          <SideNavItem
            icon={<Icons.Dashboard className={`flex-0 text-[20px]`} />}
            title="Dashboard"
            href="dashboard"
            clicked={() => handleNavItem(1)}
            active={navItemId === 1}
          />

          {/* Academic */}
          <SideNavItem
            icon={<Icons.GradHat className="flex-0 text-[20px]" />}
            title="Academic"
            clicked={event => {
              event.preventDefault();
              handleNavItem(2);
            }}
            href="academic"
            active={navItemId === 2}
          >
            {/* <SideNavLink
              href={`/${comingSoonRoutes.ATTENDANCE}`}
              inactive={true}
            >
              Attendance
            </SideNavLink> */}
            <SideNavLink href="academic/class">Class</SideNavLink>
            <SideNavLink href="academic/subjects">Subjects</SideNavLink>
            {/* <SideNavLink href={`/${commingSoonRoutes.SYLLABUS}`} inactive={true}>
            Syllabus
          </SideNavLink> */}
            {/* <SideNavLink href={`/${commingSoonRoutes.EVENT}`} inactive={true}>
            Event Calender
          </SideNavLink> */}
            {/* <SideNavLink href={`/${commingSoonRoutes.SCHEDULE}`} inactive={true}>
            Schedule
          </SideNavLink> */}
          </SideNavItem>

          {/* Teacher */}
          <SideNavItem
            icon={<Icons.Dashboard className={`flex-0 text-[20px]`} />}
            title="Teachers"
            href="users/teachers"
            clicked={() => handleNavItem(3)}
            active={navItemId === 3}
          />

          {/* Assessments */}
          <SideNavItem
            icon={<Icons.Assignment className="flex-0 text-[20px]" />}
            title="Assessments"
            clicked={event => {
              event.preventDefault();
              handleNavItem(4);
            }}
            href="assessments"
            active={navItemId === 4}
          >
            {/* <SideNavLink
              href={`/${comingSoonRoutes.ONLINE_COURSES}`}
              inactive={true}
            >
              Online courses
            </SideNavLink> */}
            <SideNavLink href="assessments/results">Results</SideNavLink>
          </SideNavItem>

          {/* Back Office */}
          {/* <SideNavItem
          icon={<Icons.Books className="flex-0 text-[20px]" />}
          title="Back office"
          clicked={(event) => {
            event.preventDefault();
            handleNavItem(5);
          }}
          href="back-office"
          active={navItemId === 5}
        >
          <SideNavLink href={`/${commingSoonRoutes.LIBRARY}`} inactive={true}>
            Library
          </SideNavLink>
          <SideNavLink
            href={`/${commingSoonRoutes.NOTICE_BOARD}`}
            inactive={true}
          >
            Notice board
          </SideNavLink>
          <SideNavLink
            href={`/${commingSoonRoutes.SCHOOL_MANAGER}`}
            inactive={true}
          >
            School manager
          </SideNavLink>
        </SideNavItem> */}
        </div>
      </div>
      <SchoolCard className="mt-[23px]" />
    </div>
  );
};

export default StudentSideNav;
