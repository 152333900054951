import { useState, useEffect, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import notificationIcon from "../../../assets/svg/bell.svg";
import moonIcon from "../../../assets/svg/moon.svg";
import searchBar from "../../../assets/svg/search.svg";
import ImageAvatar from "../ImageAvatar";
import SearchBar from "../ui/SearchBar";
import hamburgerMenu from "../../../assets/svg/hamburger-menu.svg";
import logo from "../../../assets/images/logo.png";
import * as Icons from "../../../shared/components/icons";

import { comingSoonRoutes } from "../../../utils/routeNames";

import { accountType } from "../../../constants/accountType";

import { useAuth } from "../../../store/authContext";
import { routes } from "../../../config/routes";
import { removeUndefined } from "../../../utils/stringUtil";

const NavBar = ({ className }) => {
  const container = useRef();

  const { user, handleLogout } = useAuth();
  const { userType, fullName, profileImage } = user || {};


  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClickOutside = event => {
    if (container.current && !container.current.contains(event.target)) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const getType = () => {
    switch (userType) {
      case accountType.SCHOOL:
        return "School";
      case accountType.TEACHER:
        return "Teacher";
      case accountType.STUDENT:
        return "Student";
      default:
        return "-- --";
    }
  };

  return (
    <div
      ref={container}
      className={["top-[0] left-[0] w-full", className].join(" ")}
    >
  
      <nav
        className={
          "flex h-[40px] w-full items-center justify-between self-stretch lg:justify-end border-b-[1px] pb-2 lg:border-b-[0px]"
        }
      >
        <div onClick={''} className='block w-[24px] lg:hidden'>
          <img src={hamburgerMenu} className='' alt='hamburgerMenu' />
        </div>
        {/* <div
          className={
            isExpanded
              ? "absolute left-0 top-0 z-10 mx-auto mt-[73px] flex h-max w-full flex-col bg-[#EAEBF0] text-xl leading-normal ease-in-out lg:hidden"
              : "absolute left-[-100%]"
          }
        >
          <ul
            onClick={''}
            className='p-inline-start-[40px] w-full h-screen md:hidden '
          >
            
          </ul>
        </div> */}


        <div className='flex items-center gap-[18px]'>
          <div className='flex flex-wrap items-center justify-center gap-2'>
            {/* <div className='flex items-start  rounded-md p-[10px]'>
              <SearchBar />
            </div> */}

            {/* <div className='flex items-start rounded-md p-[10px]'>
              <img
                src={notificationIcon}
                className='h-auto w-[20px]'
                alt='notification icon'
              />
            </div> */}

            {/* <div className='flex items-start rounded-md p-[10px]'>
              <img
                src={moonIcon}
                className='h-auto w-[20px]'
                alt='light and dark mode'
              />
            </div> */}
          </div>

          <div className='flex-end md:flex items-center gap-[8px] cursor-pointer hidden' onClick={handleToggle}>
            <div className='flex h-[40px] w-[40px] items-center justify-center overflow-hidden rounded-full bg-gray-400 bg-cover bg-center capitalize'>
              <ImageAvatar imageUrl={profileImage} fullName={removeUndefined(fullName)} />
            </div>

            <div className='md:flex cursor-pointer flex-col items-start font-body hidden'>
              <h4 className=' text-[14px] font-medium capitalize leading-[20px] text-[#272d37]'>
                {removeUndefined(fullName)}
              </h4>

              <p className='text-[14px] font-normal leading-[20px] text-[#5F6D7E]'>
                {getType()}
              </p>
            </div>
            <button
                className="inline-flex h-[6px] w-[12px] items-center justify-center self-center bg-transparent"
               
              >
                <Icons.DownArrow className="flex-0 h-full" color="#C9CED6" />
                {isExpanded ? (
                  <div className="absolute top-0 right-[30px] mt-[80px] rounded-3xl w-[180px] flex flex-col gap-1 z-[999] bg-white">
                    {/* <NavLink
                      className="flex h-[46px] items-center  bg-white w-full px-6 text-left text-[16px] leading-[24px] duration-100 ease-in hover:bg-active rounded"
                      to={`/${comingSoonRoutes.MY_ACCOUNT}`}
                    >
                      My Account
                    </NavLink> */}
                    {/* <NavLink
                      className="flex h-[46px] items-center  bg-white px-6 text-left text-[16px] leading-[24px] duration-100 ease-in hover:bg-active rounded"
                      to={`/${comingSoonRoutes.SETTINGS}`}
                    >
                      Settings
                    </NavLink> */}
                    {/* {userType === accountType.SCHOOL && (
                      <NavLink
                        className="flex h-[46px] items-center bg-white px-6 text-left text-[16px] leading-[24px] duration-100 ease-in hover:bg-active rounded"
                        to={routes.SUPPORT}
                      >
                        Support
                      </NavLink>
                    )} */}
                    <p
                      onClick={handleLogout}
                      className="flex h-[46px] items-center bg-white px-[16px] text-left text-[16px] leading-[24px] duration-100 ease-in hover:bg-active rounded"
                    >
                      Logout
                    </p>
                  </div>
                ) : null}
              </button>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;


