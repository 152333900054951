import { createContext, useContext, useEffect, useReducer } from "react";

import { promotionActions } from "./promotionActions";
import promotionReducer, { initialState } from "./promotionReducer";

import {
  fetchPromotionAndOrPublishStudents,
  promoteBulkStudents,
  publishStudentResults,
} from "../../services/schoolService";

import {
  showErrorToast,
  showSuccessToast,
} from "../../../../utils/toastHandler";

import { paginateData } from "../../../../utils/clientSidePagination";

const PromotionContext = createContext(null);

PromotionContext.displayName = "PromotionContext";

const PromotionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(promotionReducer, initialState);

  useEffect(() => {
    const handleStudentDataPagination = () => {
      const dataToDisplay = paginateData(state.allStudents, state.currentPage);

      dispatch({
        type: promotionActions.UPDATE_PAGINATED_DATA,
        payload: { paginatedStudentData: dataToDisplay },
      });
    };

    handleStudentDataPagination();
  }, [state.currentPage, state.allStudents]);

  const fetchInitialData = async (filterParams) => {
    dispatch({
      type: promotionActions.INITIAL_DEFAULT_LOAD,
      payload: { isLoading: true, isInitial: false },
    });

    const { currentSession, currentTerm, currentClass } = filterParams;

    dispatch({
      type: promotionActions.CACHE_FILTER_PARAMS,
      payload: {
        cachedFilterParams: { ...filterParams },
      },
    });

    const promote = currentTerm.toLowerCase() === 'third';

    try {
      const { data: responseData } = await fetchPromotionAndOrPublishStudents(
        currentClass,
        currentTerm,
        currentSession,
        promote,
      );

      if (responseData?.status === true) {
        dispatch({
          type: promotionActions.REQUEST_SUCCESS,
          payload: { allStudents: [...responseData?.data] },
        });
      } else {
        throw new Error(responseData?.message ?? "Couldn't get student's data");
      }
    } catch (err) {
      showErrorToast(err?.message ?? "Something went wrong. Try again");
      dispatch({
        type: promotionActions.ADD_ERROR,
        payload: { error: err?.message },
      });
    } finally {
      dispatch({
        type: promotionActions.UPDATE_LOADING_STATE,
        payload: { isLoading: false },
      });
    }
  };

  /// This function handle adding or removing student's data from [checkStudents]
  const handleCheckedStudent = (studentData) => {
    const cachedCheckedStudents = [...state.checkedStudents];

    if (cachedCheckedStudents.length === 0) {
      cachedCheckedStudents.push(studentData);

      dispatch({
        type: promotionActions.UPDATE_CHECKED_STUDENTS,
        payload: { checkedStudents: cachedCheckedStudents },
      });
      return;
    }

    const indexOfStudent = cachedCheckedStudents.findIndex(
      (value) => value?.student?.id === studentData?.student?.id
    );

    if (indexOfStudent === -1) {
      cachedCheckedStudents.push(studentData);
      dispatch({
        type: promotionActions.UPDATE_CHECKED_STUDENTS,
        payload: { checkedStudents: cachedCheckedStudents },
      });
      return;
    }

    cachedCheckedStudents.splice(indexOfStudent, 1);

    dispatch({
      type: promotionActions.UPDATE_CHECKED_STUDENTS,
      payload: { checkedStudents: cachedCheckedStudents },
    });
  };

  const handleCheckAllStudents = (isChecked) => {
    let cachedCheckedStudents = [...state.checkedStudents];

    if (isChecked === false) {
      dispatch({
        type: promotionActions.UPDATE_CHECKED_STUDENTS,
        payload: { checkedStudents: [] },
      });

      return;
    }

    if (cachedCheckedStudents.length === 0) {
      cachedCheckedStudents = [...state.paginatedStudentData];

      dispatch({
        type: promotionActions.UPDATE_CHECKED_STUDENTS,
        payload: { checkedStudents: cachedCheckedStudents },
      });

      return;
    }

    let pointer = 0;

    const cacheStudentIds = cachedCheckedStudents.map(
      (data) => data?.studentId
    );

    while (pointer < state.paginatedStudentData.length) {
      const studentData = state.paginatedStudentData[pointer];
      if (!cacheStudentIds.includes(studentData?.studentId)) {
        cachedCheckedStudents.push(studentData);
      }

      pointer++;
    }

    dispatch({
      type: promotionActions.UPDATE_CHECKED_STUDENTS,
      payload: { checkedStudents: cachedCheckedStudents },
    });
  };

  /// This function handles deleting checked students from [state.allStudents]
  /// and also resetting [state.checkedStudents] to an empty array []
  const deleteStudentsFromAllStudents = () => {
    const cachedCheckedStudents = [...state.checkedStudents];
    const cachedAllStudents = [...state.allStudents];

    let pointer = 0;

    while (pointer < cachedCheckedStudents.length) {
      for (let index = 0; index < cachedAllStudents.length; index++) {
        const checkedStudent = cachedCheckedStudents[pointer];
        const singleStudent = cachedAllStudents[index];

        if (checkedStudent?.student?.id === singleStudent?.student?.id) {
          cachedAllStudents.splice(index, 1);
          break;
        }
      }
      pointer++;
    }

    dispatch({
      type: promotionActions.DELETE_STUDENTS_FROM_ALL_STUDENTS,
      payload: { allStudents: cachedAllStudents, checkedStudents: [] },
    });
  };

  const _updateStudentData = (newStudentData, statusToUpdate) => {
    const isDataAnArray = Array.isArray(newStudentData);

    if (!isDataAnArray || isDataAnArray?.length === 0) {
      return;
    }

    const cachedAllStudentData = [...state.allStudents];

    let pointer = 0;

    while (pointer < newStudentData.length) {
      for (let index = 0; index < cachedAllStudentData.length; index++) {
        const newStudent = newStudentData[pointer];

        const oldStudent = cachedAllStudentData[index];
        if (newStudent?.studentId === oldStudent?.studentId) {
          const data = { ...oldStudent, [statusToUpdate]: true };

          cachedAllStudentData.splice(index, 1, data);
        }
      }

      pointer++;
    }

    dispatch({
      type: promotionActions.REQUEST_SUCCESS,
      payload: { allStudents: cachedAllStudentData },
    });
  };

  /// This function handles promoting students either using the [state.checkedStudents]
  /// if array is not empty OR [state.allStudents]
  const handlePromoteStudents = async (filterParams) => {
    dispatch({
      type: promotionActions.UPDATE_IS_PROMOTING_STATE,
      payload: { isPromoting: true },
    });

    const { nextSession, promotingTo } = filterParams;

    const cachedCheckedStudents = [...state.checkedStudents];

    const arrayOfStudentAuthIds = [];

    for (const singleStudent of cachedCheckedStudents) {
      const studentId = singleStudent?.studentAuthId;

      arrayOfStudentAuthIds.push(studentId);
    }

    const payload = {
      studentClass: state.cachedFilterParams.currentClass,
      currentSession: state.cachedFilterParams.currentSession,
      nextSession: nextSession,
      studentTerm: state.cachedFilterParams.currentTerm,
      newClass: promotingTo,
      oldClass: state.cachedFilterParams.currentClass,
      arrayOfStudents: arrayOfStudentAuthIds,
    };

    try {
      const { data } = await promoteBulkStudents(payload);

      if (data?.status === false) {
        throw new Error(
          data?.message ?? "Couldn't promote students at this time"
        );
      }

      const { data: innerData } = data;

      _updateStudentData(innerData, "promoted");

      showSuccessToast(data?.message ?? "Successfully promoted");
    } catch (err) {
      showErrorToast(err?.message ?? "Something went wrong. Try again");
    } finally {
      dispatch({
        type: promotionActions.UPDATE_IS_PROMOTING_STATE,
        payload: { isPromoting: false },
      });
    }
  };

  const _updatePublishedData = (newStudentData, errorMessage, notPublished) => {
    const isDataAnArray = Array.isArray(newStudentData);

    if (!isDataAnArray) {
      return;
    }

    if (notPublished != null) {
      const isNotPublishedAnArray = Array.isArray(notPublished);

      if (isNotPublishedAnArray) {
        // TODO: Dispatch error message here
        if (newStudentData.length === notPublished.length) {
          return;
        }

        /// This array will hold
        const studentResultToUpdatePublishedStatus = [];

        // Filter data that had errors
        let pointerForNonPublished = 0;

        while (pointerForNonPublished < notPublished.length) {
          for (const data of newStudentData) {
            const notPublishedStudentId = notPublished[pointerForNonPublished];

            if (notPublishedStudentId !== data?.studentId) {
              studentResultToUpdatePublishedStatus.push(data);
            }
          }

          pointerForNonPublished++;
        }

        const cachedAllStudentData = [...state.allStudents];

        let pointer = 0;

        while (pointer < newStudentData.length) {
          for (let index = 0; index < cachedAllStudentData.length; index++) {
            const newStudent = newStudentData[pointer];

            const oldStudent = cachedAllStudentData[index];
            if (newStudent?.studentId === oldStudent?.studentId) {
              const data = { ...oldStudent, published: true };

              cachedAllStudentData.splice(index, 1, data);
            }
          }

          pointer++;
        }
      }
    }
  };

  /// This function handles publishing all students result
  const handlePublishStudentResults = async () => {
    dispatch({
      type: promotionActions.UPDATE_IS_PUBLISHING_STATE,
      payload: { isPublishing: true },
    });

    const payload = {
      studentClass: state.cachedFilterParams.currentClass,
      studentYear: state.cachedFilterParams.currentSession,
      studentTerm: state.cachedFilterParams.currentTerm,
    };

    try {
      const { data } = await publishStudentResults(payload);

      if (data?.status === false) {
        throw new Error(
          data?.message ?? "Couldn't publish students at this time"
        );
      }

      const { data: innerData, errorMessage } = data;

      _updateStudentData(innerData, "published");

      const isErrorMessageAnArray = Array.isArray(errorMessage);

      showSuccessToast(data?.message ?? "Success");

      if (isErrorMessageAnArray && errorMessage.length >= 1) {
        dispatch({
          type: promotionActions.UPDATE_PUBLISHED_ERRORS,
          payload: { publishedErrorMessages: errorMessage },
        });
      }
    } catch (err) {
      showErrorToast(err?.message ?? "Something went wrong. Try again");
    } finally {
      dispatch({
        type: promotionActions.UPDATE_IS_PUBLISHING_STATE,
        payload: { isPublishing: false },
      });
    }
  };

  const fetchNextData = () => {
    dispatch({ type: promotionActions.INCREMENT_PAGE_COUNT });
  };

  const fetchPreviousData = () => {
    dispatch({ type: promotionActions.DECREMENT_PAGE_COUNT });
  };

  const clearPublishedErrors = () => {
    dispatch({ type: promotionActions.CLEAR_PUBLISHED_ERRORS });
  };

  const values = {
    state,
    fetchInitialData,
    handleCheckedStudent,
    handleCheckAllStudents,
    deleteStudentsFromAllStudents,
    handlePromoteStudents,
    handlePublishStudentResults,
    clearPublishedErrors,
    fetchNextData,
    fetchPreviousData,
  };

  return (
    <PromotionContext.Provider value={values}>
      {children}
    </PromotionContext.Provider>
  );
};

const usePromotion = () => {
  const context = useContext(PromotionContext);

  if (context === undefined) {
    throw new Error("usePromotion must be used within PromotionContext");
  }

  return context;
};

export { PromotionProvider, usePromotion };
